import React, { useState, useEffect } from 'react';
import { FaTrash, FaExpand } from 'react-icons/fa';
import style from './AllHeroBanners.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const AllHeroBanners = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([])

  useEffect(() => {
    const getAllBanners = async () => {
      await axios.get('https://api.mufcredbrigade.com/api/all-banners')
        .then((res) => {
          setImages(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getAllBanners();
  }, []);

  const handleDelete = async (imageId) => {
    // Use SweetAlert for confirmation
    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Delete the image
        try {
          await axios.delete(`https://api.mufcredbrigade.com/api/delete-banner/${imageId}`);
          
          // Filter out the deleted image from the state
          setImages(images.filter((image) => image._id !== imageId));
          
          // Show success message
          Swal.fire(
            'Deleted!',
            'The banner has been deleted.',
            'success'
          );
        } catch (err) {
          console.log(err);
          Swal.fire(
            'Error',
            'There was an issue deleting the banner. Please try again.',
            'error'
          );
        }
      }
    });
  };

  const handleClick = (image) => {
    setSelectedImage(image); // Show the selected image
  };

  return (
    <div className={style.gallery}>
      {images && images.map((image) => (
        <div 
          key={image._id} 
          className={style.imageContainer}
          onClick={() => handleClick(image.imageUrl)}
        >
          <img src={`https://api.mufcredbrigade.com/${image.imageUrl}`} alt={`Banner ${image._id}`} className={style.image} />
          <div className={style.overlay}>
            <FaTrash 
              className={style.deleteIcon} 
              onClick={(e) => { 
                e.stopPropagation(); // Prevent click on image while clicking delete
                handleDelete(image._id); 
              }}
            />
          </div>
        </div>
      ))}

      {selectedImage && (
        <div className={style.modal} onClick={() => setSelectedImage(null)}>
          <FaExpand className={style.expandIcon} />
          <img src={`https://api.mufcredbrigade.com/${selectedImage}`} alt="Selected" className={style.modalImage} />
        </div>
      )}
    </div>
  );
};

export default AllHeroBanners;