import React, { useState } from 'react';
import Sidebar from './../Sidebar/Sidebar';
import MembersComponent from './../MembersComponent/MembersComponent';
import DonationsComponent from './../DonationsComponent/DonationsComponent';
import AdminsComponent from './../AdminsComponent/AdminsComponent';
import BlogsComponent from './../BlogsComponent/BlogsComponent';
import AddBlogComponent from './../AddBlogComponent/AddBlogComponent';
import AddAdminComponent from './../AddAdminComponent/AddAdminComponent';
import styles from './DashboardComponent.module.css';
import DashboardMain from './../DashboardMain/DashboardMain'
import AddCommittee from '../AddCommittee/AddCommittee';
import AllCommittees from './../AllCommittees/AllCommittees'
import AllMembers from '../AllMembers/AllMembers';
import AddProduct from '../AddProduct/AddProduct';
import AllProducts from '../AllProducts/AllProducts';
import AddBoard from '../AddBoard/AddBoard';
import AllBoards from '../AllBoards/AllBoards';
import AllHeroBanners from '../AllHeroBanners/AllHeroBanners';
import AddHeroBanner from '../AddHeroBanner/AddHeroBanner';

const DashboardComponent = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <DashboardMain />;
      case 'members':
        return <MembersComponent />;
      case 'add committee':
        return <AddCommittee />;
      case 'all committees':
        return <AllCommittees />;
      case 'donations':
        return <DonationsComponent />;
      case 'admins':
        return <AdminsComponent />;
      case 'blogs':
        return <BlogsComponent />;
      case 'addBlog':
        return <AddBlogComponent />;
      case 'addAdmin':
        return <AddAdminComponent />;
      case "all members":
        return <AllMembers />
      case "add product":
        return <AddProduct />
      case "all product":
        return <AllProducts />
      case "add board":
        return <AddBoard />
      case "all board":
        return <AllBoards />
      case "all banners":
        return <AllHeroBanners />
      case "add banner":
        return <AddHeroBanner />
      default:
        return <DashboardMain />;
    }
  };

  return (
    <div className={styles.dashboard}>
      <Sidebar setActiveComponent={setActiveComponent} activeComponent={activeComponent} />
      <div className={styles.content}>
        {renderComponent()}
      </div>
    </div>
  );
};

export default DashboardComponent;