import React, { useState } from 'react';
import style from './AddHeroBanner.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const AddHeroBanner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Handle submit state

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Generate preview
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      Swal.fire({
        icon: 'error',
        title: 'No Image Selected',
        text: 'Please select an image to upload!',
      });
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedImage);
    
    setIsSubmitting(true); // Set loading state

    try {
      const res = await axios.post('https://api.mufcredbrigade.com/api/add-banner', formData);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Banner added successfully!',
      });

      // Reset form and image preview
      setSelectedImage(null);
      setPreviewUrl(null);
      setIsSubmitting(false); // Turn off loading state
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'There was an error uploading the banner. Please try again.',
      });
      setIsSubmitting(false); // Turn off loading state
    }
  };

  return (
    <form onSubmit={handleSubmit} className={style.heroForm}>
      <div className={style.inputWrapper}>
        <label htmlFor="imageUpload" className={style.label}>Upload Hero Banner:</label>
        <input 
          type="file" 
          id="imageUpload" 
          accept="image/*" 
          onChange={handleImageChange} 
          className={style.fileInput} 
        />
      </div>
      
      {previewUrl && (
        <div className={style.previewContainer}>
          <p className={style.previewText}>Image Preview:</p>
          <img src={previewUrl} alt="Preview" className={style.previewImage} />
        </div>
      )}
      
      <button 
        type="submit" 
        className={style.submitButton}
        disabled={isSubmitting} // Disable button during submission
      >
        {isSubmitting ? 'Uploading...' : 'Submit Banner'}
      </button>
    </form>
  );
};

export default AddHeroBanner;